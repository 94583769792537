import React from 'react';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import OwlCarousel from 'react-owl-carousel';

const TestimonialSection = () => {
  const testimonials = [
    {
      logo: '../assets/img/testimonials/user/download.png',
      userImage: 'assets/img/testimonials/user/download.png',
      quote: 'تیلور یک طراح حرفه‌ای است که با ارائه خدمات حرفه اش واقعاً به تجارت من کمک کرد ارزش به کسب و کار من بخشید',
      name: 'براندون فاراستر',
      designation: 'توسعه دهنده ارشد نرم افزار، Cosmic Sport',
    },
    {
      logo: 'assets/img/testimonials/logo/2.png',
      userImage: 'assets/img/testimonials/user/2.jpg',
      quote: 'تیلور یک طراح حرفه‌ای است که با ارائه خدمات حرفه اش واقعاً به تجارت من کمک کرد ارزش به کسب و کار من بخشید',
      name: 'تام بایلی',
      designation: 'متخصص SEO، Theme Junction',
    },
    // Add more testimonials as needed
  ];

  return (
    <section className="testimonial-section" id="testimonials-section">
      <div className="container">
        <div className="row">
          <div className="col-lg-5">
            <div className="section-header">
              <h2 className="section-title wow fadeInLeft" data-wow-delay=".3s">
                داستان های مشتری من
              </h2>
              <p className="wow fadeInLeft" data-wow-delay=".4s">
                توانمندسازی پروژه ها در یک سفر دیجیتال جدید با خدمات فوق العاده من
              </p>
            </div>
          </div>
          <div className="col-lg-7 col-xl-6 offset-xl-1">
            <div className="testimonials-widget wow fadeInRight" data-wow-delay=".5s">
              <OwlCarousel
                className="owl-theme testimonial-carousel"
                loop
                margin={10}
                nav
                items={1}
              >
                {testimonials.map((testimonial, index) => (
                  <div className="testimonial-item" key={index}>
                    <div className="top-area d-flex flex-wrap justify-content-between">
                      <div className="logo-box">
                        <img src={testimonial.logo} alt="" />
                      </div>
                      <div className="image-box">
                        <img src={testimonial.userImage} alt="" />
                      </div>
                    </div>
                    <div className="icon-box">
                      {/* Add your SVG icons here if needed */}
                    </div>
                    <p className="quote">{testimonial.quote}</p>
                    <h4 className="name">{testimonial.name}</h4>
                    <span className="designation">{testimonial.designation}</span>
                  </div>
                ))}
              </OwlCarousel>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TestimonialSection;
