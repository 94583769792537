import React, { useEffect } from 'react';

const HeroSection = () => {
  useEffect(() => {
    // اگر نیاز به اضافه کردن کلاس animate-stroke دارید، اینجا انجام می‌دهید
    const svgText = document.querySelector('.hero-section .intro_text svg text');
    if (svgText) {
      svgText.classList.add('animate-stroke');
    } else {
      console.error('Element not found');
    }
  }, []); // این کد فقط یک بار بعد از رندر اجرا می‌شود

  return (
    <section className="hero-section d-flex align-items-center" id="intro">
      <div className="intro_text">
        <svg viewBox="0 0 1320 300">
          <text x="50%" y="50%" textAnchor="middle">HI</text>
        </svg>
      </div>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-6">
            <div className="hero-content-box">
              <span className="hero-sub-title">من جرولد هستم</span>
              <h1 className="hero-title">برنامه نویس و<br />طراح UX</h1>

              <div className="hero-image-box d-md-none text-center">
                <img src="assets/img/hero/me.png" alt="Gerold" />
              </div>

              <p className="lead">
                من مشکلات پیچیده کاربران در زمینه طراحی و برنامه نویسی را با توجه به سابقه کاریی که دارم به راحتی برطرف میکنم
              </p>
              <div className="button-box d-flex flex-wrap align-items-center">
                <a href="#" className="btn tj-btn-secondary">دریافت رزومه <i className="flaticon-download"></i></a>
                <ul className="ul-reset social-icons">
                  <li><a href="#"><i className="fa-brands fa-twitter"></i></a></li>
                  <li><a href="#"><i className="fa-light fa-basketball"></i></a></li>
                  <li><a href="#"><i className="fa-brands fa-linkedin-in"></i></a></li>
                  <li><a href="#"><i className="fa-brands fa-github"></i></a></li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-6 d-none d-md-block">
            <div className="hero-image-box text-center">
              <img src="assets/img/hero/me.png" alt="Gerold" />
            </div>
          </div>
        </div>
        <div className="funfact-area">
          <div className="row">
            <div className="col-6 col-lg-3">
              <div className="funfact-item d-flex flex-column flex-sm-row flex-wrap align-items-center">
                <div className="number"><span className="odometer" data-count="14">0</span></div>
                <div className="text">سابقه <br />کاری</div>
              </div>
            </div>
            <div className="col-6 col-lg-3">
              <div className="funfact-item d-flex flex-column flex-sm-row flex-wrap align-items-center">
                <div className="number"><span className="odometer" data-count="50">0</span>+</div>
                <div className="text">پروژه های <br />تکمیل شده</div>
              </div>
            </div>
            <div className="col-6 col-lg-3">
              <div className="funfact-item d-flex flex-column flex-sm-row flex-wrap align-items-center">
                <div className="number"><span className="odometer" data-count="1.5">0</span>K</div>
                <div className="text">میزان <br />رضایت</div>
              </div>
            </div>
            <div className="col-6 col-lg-3">
              <div className="funfact-item d-flex flex-column flex-sm-row flex-wrap align-items-center">
                <div className="number"><span className="odometer" data-count="14">0</span></div>
                <div className="text">سال های <br />تجربیات</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
