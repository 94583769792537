import Preloader from "./components/Preloader";
import BackToTop from "./components/BackToTop";
import Header from "./components/Header";
import HeroSection from "./components/HeroSection";
import ServicesSection from "./components/ServicesSection";
import ServicePopup from "./components/ServicePopup";
import ResumeSection from "./components/ResumeSection";
import SkillsSection from "./components/SkillsSection";
import TestimonialSection from "./components/TestimonialSection";
import ContactSection from "./components/ContactSection";
import Footer from "./components/Footer";
function App() {
  // داده‌های سابقه کاری


  return (
    <div className="App">
      {/* Preloader */}
      <Preloader />
      <BackToTop />
      <Header />
      <HeroSection />
      <ServicesSection />
      <ServicePopup />
      <SkillsSection />
      <ResumeSection />
      <TestimonialSection />
      <ContactSection />
      <Footer />
    </div>
  );
}

export default App;
