import React from "react";

const ContactSection = () => {
  return (
    <section className="contact-section" id="contact-section">
      <div className="container">
        <div className="row">
          {/* فرم تماس */}
          <div className="col-lg-6 col-md-7 order-2 order-md-1">
            <div
              className="contact-form-box wow fadeInLeft"
              data-wow-delay=".3s"
            >
              <div className="section-header">
                <h2 className="section-title">همکاری با ما</h2>
                <p>
                  من چیزهای ساده و زیبا را طراحی و کدنویسی می کنم و کاری را که
                  انجام می دهم دوست دارم. همینطور ساده!
                </p>
              </div>

              <div className="tj-contact-form">
                <form id="contact-form">
                  <div className="row gx-3">
                    <div className="col-sm-6">
                      <div className="form_group">
                        <input
                          type="text"
                          name="conName"
                          id="conName"
                          placeholder="نام"
                          autoComplete="off"
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form_group">
                        <input
                          type="text"
                          name="conLName"
                          id="conLName"
                          placeholder="نام خانوادگی"
                          autoComplete="off"
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form_group">
                        <input
                          type="email"
                          name="conEmail"
                          id="conEmail"
                          placeholder="ایمیل"
                          autoComplete="off"
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form_group">
                        <input
                          type="tel"
                          name="conPhone"
                          id="conPhone"
                          placeholder="شماره تماس"
                          autoComplete="off"
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form_group">
                        <select
                          name="conService"
                          id="conService"
                          className="tj-nice-select"
                        >
                          <option value="" selected disabled>
                            نوع خدمات
                          </option>
                          <option value="braning">طراحی برندینگ</option>
                          <option value="web">طراحی سایت</option>
                          <option value="uxui">طراحی /UX</option>
                          <option value="app">طراحی اپلیکیشن</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form_group">
                        <textarea
                          name="conMessage"
                          id="conMessage"
                          placeholder="متن"
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form_btn">
                        <button type="submit" className="btn tj-btn-primary">
                          ارسال پیام
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          {/* اطلاعات تماس */}
          <div className="col-lg-5 offset-lg-1 col-md-5 d-flex flex-wrap align-items-center order-1 order-md-2">
            <div className="contact-info-list">
              <ul className="ul-reset">
                <li
                  className="d-flex flex-wrap align-items-center position-relative wow fadeInRight"
                  data-wow-delay=".4s"
                >
                  <div className="icon-box">
                    <i className="flaticon-phone-call"></i>
                  </div>
                  <div className="text-box">
                    <p>شماره تماس</p>
                    <a href="tel:0123456789">+01 123 654 8096</a>
                  </div>
                </li>
                <li
                  className="d-flex flex-wrap align-items-center position-relative wow fadeInRight"
                  data-wow-delay=".5s"
                >
                  <div className="icon-box">
                    <i className="flaticon-mail-inbox-app"></i>
                  </div>
                  <div className="text-box">
                    <p>ایمیل</p>
                    <a href="mailto:mail@mail.com">gerolddesign@mail.com</a>
                  </div>
                </li>
                <li
                  className="d-flex flex-wrap align-items-center position-relative wow fadeInRight"
                  data-wow-delay=".6s"
                >
                  <div className="icon-box">
                    <i className="flaticon-location"></i>
                  </div>
                  <div className="text-box">
                    <p>آدرس</p>
                    <a href="#">
                      گیشای غربی کوچه 38, <br />
                      فاز 57, تهران
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactSection;
