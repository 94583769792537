import React from "react";

const Footer = () => {
  return (
    <footer className="tj-footer-area">
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center">
            {/* لوگوی فوتر */}
            <div className="footer-logo-box">
              <a href="#">
                <img src="assets/img/logo/logo.png" alt="لوگو" />
              </a>
            </div>

            {/* منوی فوتر */}
            <div className="footer-menu">
              <nav>
                <ul>
                  <li>
                    <a href="#services-section">خدمات</a>
                  </li>
                  <li>
                    <a href="#works-section">نمونه کار</a>
                  </li>
                  <li>
                    <a href="#resume-section">رزومه</a>
                  </li>
                  <li>
                    <a href="#skills-section">مهارت ها</a>
                  </li>
                  <li>
                    <a href="#testimonials-section">گواهینامه ها</a>
                  </li>
                  <li>
                    <a href="#contact-section">تماس با ما</a>
                  </li>
                </ul>
              </nav>
            </div>

            {/* متن کپی رایت */}
            <div className="copy-text">
              <p>
                &copy; تمامی حقوق این سایت{" "}
                <a href="#" target="_blank" rel="noopener noreferrer">
                  محفوظ میباشد
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
