import React from "react";

const ResumeSection = () => {

    const workExperience = [
        { time: "2022 - حضور در", title: "توسعه دهنده اصلی", institute: "بروکلی، لندن" },
        { time: "2021 - 2022", title: "فول استک وب دولوپر", institute: "تهران، گیشای غربی" },
        { time: "2020 - 2021", title: "طراحی UI", institute: "خانه رویایی، وین" },
        { time: "2018 - 2020", title: "طراحی گرافیکی", institute: "ادویسل، واشنگتن" },
      ];
    
      // داده‌های تحصیلات
      const education = [
        { time: "2020 - 2023", title: "دوره برنامه نویسی", institute: "دانشگاه هاروارد" },
        { time: "2016 - 2020", title: "دوره طراحی گرافیک", institute: "دانشگاه دانمارک" },
        { time: "2012 - 2015", title: "دوره طراحی سایت", institute: "دانشگاه کالیفورنیا" },
        { time: "2010 - 2011", title: "فناوری طراحی", institute: "دانشکده فنی" },
      ];

  return (
    <section className="resume-section" id="resume-section">
      <div className="container">
        <div className="row">
          {/* بخش سابقه کاری */}
          <div className="col-md-6">
            <div className="section-header wow fadeInUp" data-wow-delay=".3s">
              <h2 className="section-title">
                <i className="flaticon-recommendation"></i> سابقه کاری من
              </h2>
            </div>
            <div className="resume-widget">
              {workExperience.map((item, index) => (
                <div
                  className="resume-item wow fadeInLeft"
                  data-wow-delay={`${0.4 + index * 0.1}s`}
                  key={index}
                >
                  <div className="time">{item.time}</div>
                  <h3 className="resume-title">{item.title}</h3>
                  <div className="institute">{item.institute}</div>
                </div>
              ))}
            </div>
          </div>

          {/* بخش تحصیلات */}
          <div className="col-md-6">
            <div className="section-header wow fadeInUp" data-wow-delay=".4s">
              <h2 className="section-title">
                <i className="flaticon-graduation-cap"></i> تحصیلات من
              </h2>
            </div>
            <div className="resume-widget">
              {education.map((item, index) => (
                <div
                  className="resume-item wow fadeInRight"
                  data-wow-delay={`${0.5 + index * 0.1}s`}
                  key={index}
                >
                  <div className="time">{item.time}</div>
                  <h3 className="resume-title">{item.title}</h3>
                  <div className="institute">{item.institute}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ResumeSection;
