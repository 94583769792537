import React from "react";

const SkillsSection = () => {
  // داده‌های مهارت‌ها
  const skills = [
    { id: 1, icon: "assets/img/icons/figma.svg", name: "فیگما", percentage: "92%", delay: ".3s" },
    { id: 2, icon: "assets/img/icons/sketch.svg", name: "Sketch", percentage: "80%", delay: ".4s" },
    { id: 3, icon: "assets/img/icons/xd.svg", name: "XD", percentage: "85%", delay: ".5s" },
    { id: 4, icon: "assets/img/icons/wp.svg", name: "وردپرس", percentage: "99%", delay: ".6s" },
    { id: 5, icon: "assets/img/icons/react.svg", name: "ری اکت", percentage: "89%", delay: ".7s" },
    { id: 6, icon: "assets/img/icons/js.svg", name: "جاوا اسکریپت", percentage: "93%", delay: ".8s" },
  ];

  return (
    <section className="skills-section" id="skills-section">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="section-header text-center">
              <h2 className="section-title wow fadeInUp" data-wow-delay=".3s">
                مهارت ها
              </h2>
              <p className="wow fadeInUp" data-wow-delay=".4s">
                ما ایده های شما و در نتیجه خواسته های شما را در قالب یک پروژه وب منحصر به فرد قرار می دهیم که الهام بخش شما و مشتریان شما باشد.
              </p>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="skills-widget d-flex flex-wrap justify-content-center align-items-center">
              {skills.map((skill) => (
                <div
                  key={skill.id}
                  className="skill-item wow fadeInUp"
                  data-wow-delay={skill.delay}
                >
                  <div className="skill-inner">
                    <div className="icon-box">
                      <img src={skill.icon} alt={skill.name} />
                    </div>
                    <div className="number">{skill.percentage}</div>
                  </div>
                  <p>{skill.name}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SkillsSection;
