import React from 'react';
const ServicesSection = () => {
  return (
    <section className="services-section" id="services-section">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="section-header text-center">
              <h2 className="section-title wow fadeInUp" data-wow-delay=".3s">
                خدمات با کیفیت من
              </h2>
              <p className="wow fadeInUp" data-wow-delay=".4s">
                ما ایده های شما و در نتیجه خواسته های شما را در قالب یک پروژه وب منحصر به فرد قرار می دهیم که الهام بخش شما و مشتریان باشد.
              </p>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="services-widget position-relative">
              <div className="service-item current d-flex flex-wrap align-items-center wow fadeInUp" data-wow-delay=".5s">
                <div className="left-box d-flex flex-wrap align-items-center">
                  <span className="number">01</span>
                  <h3 className="service-title">طراحی برندینگ</h3>
                </div>
                <div className="right-box">
                  <p>
                    من مشکلات پیچیده کاربران در زمینه طراحی و برنامه نویسی را با توجه به سابقه کاریی که دارم به راحتی برطرف میکنم
                  </p>
                </div>
                <i className="flaticon-up-right-arrow"></i>
                <button data-mfp-src="#service-wrapper" className="service-link modal-popup"></button>
              </div>
              <div className="service-item d-flex flex-wrap align-items-center wow fadeInUp" data-wow-delay=".6s">
                <div className="left-box d-flex flex-wrap align-items-center">
                  <span className="number">02</span>
                  <h3 className="service-title">طراحی سایت</h3>
                </div>
                <div className="right-box">
                  <p>
                    من مشکلات پیچیده کاربران در زمینه طراحی و برنامه نویسی را با توجه به سابقه کاریی که دارم به راحتی برطرف میکنم
                  </p>
                </div>
                <i className="flaticon-up-right-arrow"></i>
                <button data-mfp-src="#service-wrapper" className="service-link modal-popup"></button>
              </div>
              <div className="service-item d-flex flex-wrap align-items-center wow fadeInUp" data-wow-delay=".7s">
                <div className="left-box d-flex flex-wrap align-items-center">
                  <span className="number">03</span>
                  <h3 className="service-title">طراحی UI/UX</h3>
                </div>
                <div className="right-box">
                  <p>
                    من مشکلات پیچیده کاربران در زمینه طراحی و برنامه نویسی را با توجه به سابقه کاریی که دارم به راحتی برطرف میکنم
                  </p>
                </div>
                <i className="flaticon-up-right-arrow"></i>
                <button data-mfp-src="#service-wrapper" className="service-link modal-popup"></button>
              </div>
              <div className="service-item d-flex flex-wrap align-items-center wow fadeInUp" data-wow-delay=".8s">
                <div className="left-box d-flex flex-wrap align-items-center">
                  <span className="number">04</span>
                  <h3 className="service-title">طراح گرافیک</h3>
                </div>
                <div className="right-box">
                  <p>
                    من مشکلات پیچیده کاربران در زمینه طراحی و برنامه نویسی را با توجه به سابقه کاریی که دارم به راحتی برطرف میکنم
                  </p>
                </div>
                <i className="flaticon-up-right-arrow"></i>
                <button data-mfp-src="#service-wrapper" className="service-link modal-popup"></button>
              </div>
              <div className="active-bg wow fadeInUp" data-wow-delay=".5s"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServicesSection;
