import React, { useState } from 'react';

const ServicePopup = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="popup_content_area zoom-anim-dialog">
      <div className="popup_modal_img">
        <img src="assets/img/services/modal-img.jpg" alt="" />
      </div>

      <div className="popup_modal_content">
        <div className="service_details">
          <div className="row">
            <div className="col-lg-7 col-xl-8">
              <div className="service_details_content">
                <div className="service_info">
                  <h6 className="subtitle">خدمات</h6>
                  <h2 className="title">طراح UI/UX </h2>
                  <div className="desc">
                    <p>لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است...</p>
                    <p>لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است...</p>
                    <p>لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است...</p>
                  </div>

                  <h3 className="title">فرآیند خدمات</h3>
                  <div className="desc">
                    <p>لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است...</p>
                  </div>
                  <ul>
                    <li>کسب و کار خود را دوباره شروع و بهتر شوید</li>
                    <li>پیشگام در اینترنت</li>
                    <li>پیشگام در دنیای طراحی</li>
                    <li>پیشگام در دنیای طراحی</li>
                    <li>پیشگام در دنیای طراحی</li>
                    <li>پیشگام در دنیای طراحی</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-xl-4">
              <div className="tj_main_sidebar">
                <div className="sidebar_widget services_list">
                  <div className="widget_title">
                    <h3 className="title">تمامی خدمات</h3>
                  </div>
                  <ul>
                    <li className="active">
                      <button>
                        <i className="flaticon-design"></i>
                        طراحی برندینگ
                      </button>
                    </li>
                    <li>
                      <button>
                        <i className="flaticon-3d-movie"></i>
                        انیمیشن سه بعدی
                      </button>
                    </li>
                    <li>
                      <button>
                        <i className="flaticon-ux-design"></i>
                        طراحی UI/UX
                      </button>
                    </li>
                    <li>
                      <button>
                        <i className="flaticon-web-design"></i>
                        طراحی وبسایت
                      </button>
                    </li>
                    <li>
                      <button>
                        <i className="flaticon-ui-design"></i>
                        طراحی اپکیشن
                      </button>
                    </li>
                  </ul>
                </div>

                <div className="sidebar_widget contact_form">
                  <div className="widget_title">
                    <h3 className="title">در تماس باش</h3>
                  </div>

                  <form action="index.html">
                    <div className="form_group">
                      <input type="text" name="name" id="name" placeholder="نام" autocomplete="off" />
                    </div>
                    <div className="form_group">
                      <input type="email" name="semail" id="semail" placeholder="ایمیل" autocomplete="off" />
                    </div>
                    <div className="form_group">
                      <textarea name="smessage" id="smessage" placeholder="پیام شما" autocomplete="off"></textarea>
                    </div>
                    <div className="form_btn">
                      <button className="btn tj-btn-primary" type="submit">ارسال پیام</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <button onClick={onClose} className="close-btn">بستن</button>
    </div>
  );
};

export default ServicePopup;
